import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { auth, db } from './Firebase'; // Import auth and db
import { getDoc, setDoc, doc } from 'firebase/firestore';
import { signInWithCustomToken } from 'firebase/auth';
import LogoutButton from './LogoutButton';

// Utility to generate a random publicId
function generatePublicId() {
  return Math.random().toString(36).substr(2, 9);
}

const authenticateWithFirebase = async (auth0Token) => {
  try {
    console.log("Authenticating with Firebase using custom token");

    // Fetch Firebase custom token from backend
    const response = await fetch('/api/getFirebaseCustomToken', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ auth0Token }),
    });

    if (!response.ok) {
      console.error('Failed to fetch Firebase custom token:', response.status, response.statusText);
      throw new Error(`Failed to fetch Firebase custom token: ${response.statusText}`);
    }

    const data = await response.json();
    console.log('Response data:', data);

    const { firebaseCustomToken } = data;

    // Sign in to Firebase with the custom token
    await signInWithCustomToken(auth, firebaseCustomToken);

    console.log('Successfully signed in to Firebase');
  } catch (error) {
    console.error('Error signing in to Firebase:', error);
    throw error;
  }
};

const TemplateForm = ({ clientId }) => {
  const { getAccessTokenSilently, logout } = useAuth0();
  const [config, setConfig] = useState({
    primaryColor: '',
    secondaryColor: '',
    contrastColor: '',
    backgroundColor: '',
    fontPrimary: '',
    emailAddress: '',
  });

  const [embedCode, setEmbedCode] = useState('');

  const handleLogout = () => {
    localStorage.removeItem('isLoggedIn');
    logout({
      returnTo: window.location.origin, // Redirect to the current origin (localhost or production)
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
    });
  };

  useEffect(() => {
    const fetchOrCreateConfig = async () => {
      try {
        if (!clientId) {
          console.error("Client ID is undefined");
          return;
        }

        const auth0Token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: 'openid profile email',
        });

        await authenticateWithFirebase(auth0Token);

        const userDocRef = doc(db, 'clientConfigs', clientId);
        const userDoc = await getDoc(userDocRef);

        let userConfig = userDoc.data() || {};

        if (userDoc.exists()) {
          setConfig(userConfig);
        } else {
          userConfig = {
            primaryColor: '#3498DB',
            secondaryColor: '#AEDFF7',
            contrastColor: '#ea9b47',
            backgroundColor: '#FFFFFF',
            fontPrimary: 'Open Sans, sans-serif',
          };

          await setDoc(userDocRef, userConfig);
          setConfig(userConfig);
        }

        let { publicId } = userConfig;
        if (!publicId) {
          publicId = generatePublicId();
          userConfig.publicId = publicId;
          await setDoc(userDocRef, { publicId }, { merge: true });
        }

        const codeSnippet = `<div id="food-form-container"></div>
<script src="https://collaborative-catering-21ab4e9a8bfb.herokuapp.com/embed-script/${publicId}"></script>`;
        setEmbedCode(codeSnippet);
      } catch (error) {
        console.error('Error in fetchOrCreateConfig:', error);
      }
    };

    fetchOrCreateConfig();
  }, [clientId, getAccessTokenSilently]);

  const handleChange = (e) => {
    setConfig({ ...config, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!clientId) {
        console.error("Client ID is undefined");
        return;
      }

      const userDocRef = doc(db, 'clientConfigs', clientId);

      await setDoc(userDocRef, config, { merge: true });

      alert('Configuration updated successfully.');
    } catch (error) {
      console.error('Error updating config:', error);
      alert('Failed to update the configuration. Please try again.');
    }
  };

  return (
    <div className="drinkCardGuest">
      <form onSubmit={handleSubmit}>
        <label>
          Primary Color:
          <input type="text" name="primaryColor" value={config.primaryColor} onChange={handleChange} />
        </label>
        <label>
          Secondary Color:
          <input type="text" name="secondaryColor" value={config.secondaryColor} onChange={handleChange} />
        </label>
        <label>
          Contrast Color:
          <input type="text" name="contrastColor" value={config.contrastColor} onChange={handleChange} />
        </label>
        <label>
          Background Color:
          <input type="text" name="backgroundColor" value={config.backgroundColor} onChange={handleChange} />
        </label>
        <label>
          Font:
          <input type="text" name="fontPrimary" value={config.fontPrimary} onChange={handleChange} />
        </label>
        <label>
          Email Address:
          <input type="email" name="emailAddress" value={config.emailAddress} onChange={handleChange} required />
        </label>
        <button type="submit">Save Configuration</button>
      </form>

      {embedCode && (
        <div style={{ marginTop: '20px' }}>
          <h3>Embed This Form on Your Website</h3>
          <p>Copy and paste the following code snippet into your website:</p>
          <pre style={{ backgroundColor: '#f6f8fa', padding: '10px', borderRadius: '5px' }}>
            {embedCode}
          </pre>
        </div>
      )}

      {/* Logout button */}
      <LogoutButton onClick={handleLogout} />
    </div>
  );
};

export default TemplateForm;
