import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = () => {
    const returnToUrl = 'https://collaborative-catering-21ab4e9a8bfb.herokuapp.com/';

    logout({
      returnTo: returnToUrl,  // Redirects to the appropriate URL based on environment
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID, // Include client ID if necessary
    });
  };

  return (
    <button onClick={handleLogout}>
      Log Out
    </button>
  );
};

export default LogoutButton;
